import { Component, EventEmitter, input, Input, OnInit, output, Output } from '@angular/core';
import { isTouchDevice } from "@app/core/const/responsive";
import { RoamIconComponent } from '../roam-icon/roam-icon.component';

@Component({
  standalone: true,
  selector: 'app-content-header',
  imports: [RoamIconComponent],
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent implements OnInit {
  @Input() title: string = '';

  @Input() action: string = '';

  @Input()
  public titleCls: string = 'font-24px'

  counter = input<boolean>(false);

  @Output()
  addItem: EventEmitter<void> = new EventEmitter();

  @Output()
  public onSelect: EventEmitter<Event> = new EventEmitter<Event>();

  onClear = output();

  public isTouchedDevice: boolean = false;

  ngOnInit(): void {
    this.isTouchedDevice = isTouchDevice();
  }

  onAddItem() {
    this.addItem.emit();
  }
}
