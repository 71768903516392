<div class="justify-between">
  <div class="d-flex align-end gap-10">
    @if (counter()) {
      <div class="align-center gap-10">
        <roam-icon class="c-pointer" name="close" size="18px" (onClick)="onClear.emit()"/>
        <p [class]="'details-header fw-500 tc-grey'">{{ title }}</p>
      </div>
    } @else {
      <p [class]="'details-header fw-500 ' + titleCls">{{ title }}</p>
    }
  </div>
</div>
